import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../NavBar.js';
import '../App.css';

function Store() {
    const [numWater, setNumWater] = useState(0);
    const [numCheese, setNumCheese] = useState(0);

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('num water submitted:', numWater);
        console.log('num cheese submitted:', numCheese);
        
        const lineItems = [];
        if (numCheese > 0) {
            lineItems.push({ price: 'price_1QDWOw2KaUsZb1Og7fbqGGWS', quantity: numCheese });
        }
        if (numWater > 0) {
            lineItems.push({ price: 'price_1QDVIR2KaUsZb1Og7rwjdUoH', quantity: numWater });
        }

        if (lineItems.length > 0) {
            navigate('/checkout', { state: { lineItems } });
        }
    }

    return (
        <div>
            <NavBar/>

            <div id="storePage">
                <h1>WELCOME TO MY STORE</h1> <br></br>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="water">Doodoo water</label>
                    <input type="number" id="water" min="0" value={numWater} onChange={(e) => setNumWater(e.target.value)}></input><br/>
                    <label htmlFor="cheese">big cheese</label>
                    <input type="number" id="cheese" min="0" value={numCheese} onChange={(e) => setNumCheese(e.target.value)}></input><br/>
                    <input type="submit" value="Checkout"></input>
                </form>
            </div>
            
        </div>
    );
}

export default Store;